@import "/src/style/variables.scss";

.descriptionContainer {
  width: 100%;
  height: 75vh;
  position: relative;
}

.showNavigationBtn {
  display: block;
  cursor: pointer;
  position: absolute;
  bottom: 30%;
  left: 44%;
  z-index: 1;
  background: transparent;
  border: 1px solid $primary-color;
  color: $primary-color;

  font-size: 32px;
  font-weight: bold;
  border-radius: 50%;
  padding: 10px 20px;
}

.animatedTextWrapper {
  height: 100px;
  width: 100%;
  position: relative;
}

.navigationContainer {
  display: none;
}

@media (min-width: $breakpoint-mobile) {
  .showNavigationBtn,
  .navigationContainer {
    display: block;
  }
  .showNavigationBtn {
    left: 48%;
  }
}
