@import "/src/style/variables.scss";

.container {
  height: 120px;
  padding: 20px;
  color: $primary-color;
  transition: backdrop-filter 0.3s ease;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: space-between;
  position: fixed;
  text-align: initial;
  top: 0;
  width: 100%;
  z-index: 3;
}

.showInfoBlock {
  position: relative;

  @media (max-width: $breakpoint-mobile-iphone) {
    position: relative;
    right: 15px;
  }
}
.showInfoHide {
  display: none;
}
.showInfoText {
  position: absolute;
  top: 25px;
  left: 10px;
  width: 150px;
  padding: 10px;
  display: flex;
  color: $main-color;
  background-color: $primary-color;
  border-radius: 4px;
  z-index: 10;
  @media (max-width: $breakpoint-mobile) {
    left: auto;
    right: 10px;
  }
}

.informationIcon {
  position: relative;
  cursor: pointer;
  z-index: 2;
}

.total {
  display: flex;
  margin: 0 auto;
  position: relative;
}

.selectedBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  right: 20px;

  @media (max-width: $breakpoint-mobile) {
    left: 70%;
    top: 60px;
    justify-content: center;
  }
}

.selectedShowsText {
  font-weight: bold;
  margin-right: 5px;

  @media (max-width: $breakpoint-mobile) {
    display: none;
    color: $brand-color;
  }
}

.startOverBtn {
  color: $primary-color-second;
  margin-left: 10px;
  font-size: 15px;
  cursor: pointer;

  @media (max-width: $breakpoint-mobile) {
    font-size: 28px;
    color: $primary-color-second;

    margin-left: 50px;
  }
  @media (max-width: $breakpoint-mobile-iphone) {
    font-size: 28px;
    position: absolute;
    left: 35px;
    color: $primary-color-second;
  }
}

.hamburger {
  display: none;
  cursor: pointer;

  .bar {
    width: 25px;
    height: 3px;
    background-color: $primary-color;
    margin: 4px 0;
  }

  @media (max-width: $breakpoint-mobile) {
    display: block;
  }
}

.navBlock {
  display: flex;
  position: absolute;
  z-index: 3;

  @media (max-width: $breakpoint-mobile) {
    display: none;

    &.open {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $second-main-background-color;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100vh;
    }
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0 1rem;
    font-size: 18px;
    font-weight: bold;
  }

  a {
    text-decoration: none;
    color: #fff;
    transition: color 0.3s;
  }

  a:hover {
    color: $brand-color;
  }
}

.material-icons-outlined {
  font-size: 70px;
}
.closeBtn {
  display: none;
  &:hover {
    background-color: transparent;
  }
  @media (max-width: $breakpoint-mobile) {
    display: block;
    top: 20px;
    right: 15px;
    position: absolute;

    text-align: center;
    cursor: pointer;
    background-color: transparent;
    font-size: 32px;
    font-weight: 900;
    z-index: 2;
  }
}
