@import "/src/style/variables.scss";
.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  object-fit: cover;
  background: transparent;
  margin-top: 15px;
  font-size: 16px;
  position: absolute;
  bottom: 10px;
  right: 0;
  background: $main-color;
  opacity: 0.7;
  color: $primary-color;
  margin-top: 30px;
  bottom: -3px;
  right: 0;
  padding: 10px;
  @media (max-width: $breakpoint-mobile) {
    padding: 5px;
  }
}

.icon {
  display: block;
  font-size: 20px;
  margin-top: 10px;
}
.seasonsText {
  text-align: left;
}

.modalWindow {
  // background-color: rgba(3, 3, 3, 0.903);
  background: linear-gradient(120deg, #a6c0fe 0%, #f68084 90%);
  height: 75vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  margin-top: 120px;
  border: none;
  @media (max-width: $breakpoint-mobile) {
    margin-top: 120px;
    border: none;
  }
}
