@import "/src/style/variables.scss";
.seasonWpapper {
  position: absolute;
  border: 1px solid $primary-color;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  padding: 16px 24px;
  width: 550px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  @media (max-width: $breakpoint-mobile) {
    width: 100%;
    margin: 0 auto;
    border: none;
    height: calc(100% - 120px);
  }
}

.closeBtn {
  position: absolute;
  top: -40px;
  right: 0;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  font-size: 32px;
  font-weight: 900;
  z-index: 2;

  &:hover {
    background-color: transparent;
  }
  @media (max-width: $breakpoint-mobile) {
    top: 20px;
    right: 15px;
  }
}
.selectedShowTitle {
  margin: 0 auto;
  font-size: 21px;
  font-weight: 800;
  position: relative;
  text-align: center;
  padding: 15px 0;

  @media (max-width: $breakpoint-mobile) {
    margin-bottom: 20px;
  }
}
.selectedShowTitle::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 25%;
  display: block;
  height: 2px;
  background: $brand-color;
  width: 50%;
}

.seasonsBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: $breakpoint-mobile) {
    margin-bottom: 20px;
  }
}

.seasonsFigure {
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  background: rgb(102, 101, 101);
  border-radius: 50%;
  cursor: pointer;
  color: $main-color;
  margin: 10px;
  opacity: 0.5;
  .seasonsFigure:hover {
    border: 1px solid $brand-color;
    color: $main-color;
    background: $brand-color;
  }
  &.isSelected {
    border: 1px solid $primary-color;
    background-color: $sub-brand-color;
    color: $main-color;

    opacity: 1;
  }
  @media (max-width: $breakpoint-mobile) {
    width: 45px;
    height: 45px;
  }
}
.name {
  font-size: 21px;
}

.inputWithButon {
  display: flex;
  align-items: center;
}

.selectLabel {
  font-size: 18px;
  margin: 10px 10px;
}

.seenTimesContainer {
  margin: 10px 0px 10px 0;
  display: flex;
  align-items: center;
}

.seenTimesNumber {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  background-color: $primary-color;
  color: $main-color;
  cursor: pointer;
  border: 1px solid $primary-color;
}
.changeNumberBtnPlus {
  display: block;
  text-align: center;
  cursor: pointer;
  border: 1px solid $primary-color;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin: 0px;
  height: 20px;
  padding-left: 10px;
  width: 40px;
  height: 40px;
  font-size: 16px;
  color: $seen-times-btn-color;
}
.changeNumberBtnMinus {
  display: block;
  text-align: center;
  cursor: pointer;
  border: 1px solid $primary-color;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin: 0px;
  height: 20px;
  padding-left: 10px;
  width: 40px;
  height: 40px;
  font-size: 16px;
  color: $seen-times-btn-color;
}
.checkmark {
  color: $main-color;
  font-size: 12px;
  margin-left: 5px;
}
