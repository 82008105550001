@import "/src/style/variables.scss";

.showsWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
}

.popularShowsTitle {
  font-size: 21px;
  margin: 50px auto;
  font-weight: bold;
  padding: 20px;
}
