.animatedContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 50vh;
  padding: 20px 0px 5px 20px;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animatedText {
  font-size: 32px;
  font-weight: bold;
  animation: slideDown 2s;
  letter-spacing: 2px;
}
