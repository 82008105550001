@import "/src/style/variables.scss";
.container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  font-weight: 200;
  line-height: 30px;
  color: $primary-color;
}
.footerLink {
  color: $primary-color;
  text-decoration: none;
}
.footerLink:hover {
  text-decoration: underline;
}
