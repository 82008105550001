@import "/src/style/variables.scss";

.container {
  margin-top: 10px;
}
.showsDescription {
  padding: 10px;
  font-size: 21px;
  text-align: center;
  padding-top: 0;
  @media (max-width: $breakpoint-mobile) {
    font-size: 18px;
    padding: 0;
  }
}
.pages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 120px;
}
.pageContainer {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}
